<template>
  <v-container fluid class="mt-4">
    <v-row>
      <v-tabs
        v-model="tab"
        background-color="transparent"
        class="mb-3"
        color="#B361FF"
        :grow="this.$vuetify.breakpoint.mobile"
        :right="!this.$vuetify.breakpoint.mobile"
      >
        <v-tab> Wszystkie </v-tab>
        <v-tab>
          <v-icon
            :elevation="'1'"
            :style="'color: rgb(255, 63, 234); font-size: 27px !important;'"
            >fas fa-heart mr-3</v-icon
          >
          Ulubione
          {{
            this.recipes_liked.length > 0
              ? `(${this.recipes_liked.length})`
              : ""
          }}
        </v-tab>
      </v-tabs>

      <v-tabs-items
        v-model="tab"
        class="bg-transparent transparent"
        :style="'width: 100% !important;'"
      >
        <v-tab-item>
          <v-container fluid>
            <v-row :class="`${$vuetify.breakpoint.mdAndUp ? 'mt-2 ml-2' : ''}`">
              <v-col cols="2" md="3" v-if="$vuetify.breakpoint.mdAndUp">
                <v-row>
                  <v-checkbox
                    v-model="selected.free"
                    v-bind:label="`Tylko darmowe (${
                      this.free.length > 0 ? this.free[0].count : 0
                    })`"
                    :value="'free'"
                    class="mt-0 free-checkbox text-typo"
                    v-if="!userHasActivePlan"
                  ></v-checkbox>
                </v-row>
                <v-row>
                  <v-col v-if="!filtersEmpty">
                    <v-btn
                      elevation="0"
                      :ripple="false"
                      height="43"
                      @click="resetFilters()"
                      class="
                        font-weight-bold
                        btn-default
                        bg-gradient-info
                        py-2
                        px-6
                        me-2
                        mt-1
                        mb-2
                      "
                      color="#5e72e4"
                      ><v-icon>fas fa-redo mr-3</v-icon> Resetuj
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-text-field
                    hide-details
                    outlined
                    background-color="rgba(255,255,255,.9)"
                    color="rgba(0,0,0,.6)"
                    height="40"
                    placeholder="Wyszukaj..."
                    v-model="searchValue"
                    @input="searchBounce()"
                    class="
                      recipe-search-input
                      input-style
                      font-size-input
                      border border-radius-md
                      mb-4
                    "
                  >
                    <template v-slot:prepend class="ma-0">
                      <v-progress-circular
                        v-if="searchLoading"
                        size="20"
                        indeterminate
                        color="purple"
                      ></v-progress-circular>
                      <v-icon v-else> fas fa-search</v-icon>
                    </template>
                  </v-text-field>
                </v-row>
                <v-row>
                  <h4 class="text-typo">Kategorie</h4>
                </v-row>
                <v-row v-for="category in categories" :key="category.value">
                  <v-checkbox
                    v-model="selected.categories"
                    v-bind:label="`${category.label} (${category.count})`"
                    :value="category.value"
                    class="mt-0"
                  ></v-checkbox>
                </v-row>
                <v-row>
                  <h4 class="text-typo">Diety</h4>
                </v-row>
                <v-row
                  v-for="(tag, index) in tags"
                  :key="`${tag.value}_${index}`"
                >
                  <v-checkbox
                    v-model="selected.tags"
                    v-bind:label="`${tag.label} (${tag.count})`"
                    :value="tag.value"
                    class="mt-0"
                  ></v-checkbox>
                </v-row>
              </v-col>
              <v-col v-else cols="12">
                <v-row>
                  <v-col cols="4" sm="3" md="2">
                    <v-btn
                      elevation="0"
                      :ripple="false"
                      height="43"
                      @click="filterDialog = true"
                      class="
                        font-weight-bold
                        text-uppercase
                        btn-default
                        bg-gradient-primary
                        py-2
                        px-6
                        me-2
                        mt-1
                        mb-2
                      "
                      color="#5e72e4"
                      ><v-icon>fas fa-filter</v-icon>
                      <span class="ml-1">Filtry</span>
                    </v-btn>
                  </v-col>
                  <v-col v-if="!filtersEmpty">
                    <v-btn
                      elevation="0"
                      :ripple="false"
                      height="43"
                      @click="resetFilters()"
                      class="
                        font-weight-bold
                        text-uppercase
                        btn-default
                        bg-gradient-info
                        py-2
                        px-6
                        me-2
                        mt-1
                        mb-2
                      "
                      color="#5e72e4"
                      ><v-icon>fas fa-redo</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12" lg="9" md="9">
                <v-row>
                  <v-col>
                    <h4 class="text-typo">{{ this.recipes.length }} wyniki</h4>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    xs="12"
                    sm="6"
                    md="6"
                    lg="4"
                    v-for="recipe in recipes.slice(page*9,(page*9)+9)"
                    :key="recipe.resourceid"
                  >
                    <v-card
                      class="transparent pa-1 thumbnail-container"
                      @click="openRecipe(recipe.resourceid)"
                    >
                      <v-img
                        :src="recipe.image.url"
                        elevation="1"
                        class="rounded thumbnail-static"
                      >
                        <template v-slot:placeholder>
                          <v-sheet
                            :color="`grey lighten-4`"
                            :style="'height: 100%; width: 100%; min-height: 200px' "
                          >
                            <v-skeleton-loader
                              type="image"
                              :color="`grey lighten-4`"
                            ></v-skeleton-loader>
                          </v-sheet>
                        </template>
                      </v-img>
                      <div class="recipe-liked" v-if="recipe.isLiked">
                        <v-icon
                          :elevation="'1'"
                          :style="'color: rgb(255, 63, 234); font-size: 35px !important;'"
                          >fas fa-heart</v-icon
                        >
                      </div>

                      <h4 class="text-typo">
                        {{ recipe.name }}
                        <v-chip
                          class="ma-2"
                          x-small
                          color="green"
                          text-color="white"
                          v-if="!userHasActivePlan && recipe.is_free"
                        >
                          FREE
                        </v-chip>
                      </h4>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row class="d-flex justify-center">
                  <v-col cols="12" class="d-inline-flex justify-center align-center">
                    <v-btn class="btn-primary button-primary-gradient" :disabled="page==0" @click="prevPage()">
                      <v-icon :style="'color: white;'" class="ma-2">
                        fas fa-chevron-left
                      </v-icon>
                    </v-btn>
                    <h4 class="text-typo mx-2">strona {{page+1}}/{{maxPage}}</h4>
                    <v-btn class="btn-primary button-primary-gradient" :disabled="page>=(maxPage-1)" @click="nextPage()">
                      <v-icon :style="'color: white;'" class="ma-2">
                        fas fa-chevron-right
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>

        <v-tab-item>
          <v-container fluid>
            <v-row>
              <v-col cols="12">
                <v-row>
                  <v-col>
                    <h4 class="text-typo">
                      {{ this.recipes_liked.length }} wyniki
                    </h4>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    xs="12"
                    sm="6"
                    md="6"
                    lg="4"
                    v-for="recipe in recipes_liked"
                    :key="recipe.resourceid"
                  >
                    <v-card
                      class="transparent pa-1 thumbnail-container"
                      @click="openRecipe(recipe.resourceid)"
                    >
                      <v-img
                        :src="recipe.image.url"
                        elevation="1"
                        class="rounded thumbnail-static"
                      >
                      </v-img>
                      <v-img
                        v-if="false"
                        :src="recipe.video_gif.url"
                        elevation="1"
                        class="rounded thumbnail-gif"
                      >
                      </v-img>
                      <h4 class="text-typo">
                        {{ recipe.name }}
                      </h4>
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
      </v-tabs-items>
    </v-row>
    <v-dialog
      v-model="filterDialog"
      :fullscreen="$vuetify.breakpoint.mobile"
      hide-overlay
      width="400"
      :transition="
        this.$vuetify.breakpoint.mobile
          ? 'slide-x-transition'
          : 'dialog-bottom-transition'
      "
    >
      <v-card>
        <v-toolbar class="bg-gradient-primary">
          <v-btn icon @click="filterDialog = false">
            <v-icon color="white">{{
              this.$vuetify.breakpoint.mobile
                ? "fas fa-chevron-left"
                : "fas fa-times"
            }}</v-icon>
          </v-btn>
          <v-toolbar-title :style="'color: white;'"
            >Filtruj przepisy</v-toolbar-title
          >
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-container class="p-4">
          <v-row>
            <v-container fluid>
              <v-checkbox
                v-model="selected.free"
                v-bind:label="`Tylko darmowe (${
                  this.free.length > 0 ? this.free[0].count : 0
                })`"
                :value="'free'"
                class="mt-0 free-checkbox text-typo"
                v-if="!userHasActivePlan"
              ></v-checkbox>
            </v-container>
          </v-row>
          <v-row>
            <v-text-field
              hide-details
              outlined
              background-color="rgba(255,255,255,.9)"
              color="rgba(0,0,0,.6)"
              height="40"
              placeholder="Wyszukaj..."
              v-model="searchValue"
              @input="searchBounce()"
              class="
                recipe-search-input
                input-style
                font-size-input
                border border-radius-md
                mb-4
              "
            >
              <template v-slot:prepend class="ma-0">
                <v-progress-circular
                  v-if="searchLoading"
                  size="20"
                  indeterminate
                  color="purple"
                ></v-progress-circular>
                <v-icon v-else> fas fa-search</v-icon>
              </template>
            </v-text-field>
          </v-row>
          <v-row>
            <v-container fluid>
              <h4 class="text-typo">Kategorie</h4>
              <v-checkbox
                v-for="(category, index) in categories"
                :key="index"
                v-model="selected.categories"
                v-bind:label="`${category.label} (${category.count})`"
                :value="category.value"
                class="mt-0"
              ></v-checkbox>
            </v-container>
          </v-row>
          <v-row>
            <v-container fluid>
              <h4 class="text-typo">Diety</h4>
              <v-checkbox
                v-for="(tag, index) in tags"
                :key="index"
                v-model="selected.tags"
                v-bind:label="`${tag.label} (${tag.count})`"
                :value="tag.value"
                class="mt-0"
              ></v-checkbox>
            </v-container>
          </v-row>
          <v-row>
            <v-col>
              <v-btn
                elevation="0"
                :ripple="false"
                height="43"
                class="
                  font-weight-bold
                  text-uppercase
                  btn-default
                  bg-gradient-primary
                  py-2
                  px-6
                  me-2
                  mt-0
                  mb-2
                  w-100
                "
                color="#5e72e4"
                @click="filterDialog = false"
                >Filtruj</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>

    <v-dialog
      v-if="currentRecipe"
      v-model="recipeDialog"
      :fullscreen="$vuetify.breakpoint.mobile"
      hide-overlay
      width="800"
      :transition="
        this.$vuetify.breakpoint.mobile
          ? 'slide-x-transition'
          : 'dialog-bottom-transition'
      "
    >
      <v-card>
        <v-toolbar class="bg-gradient-primary">
          <v-btn icon @click="recipeDialog = false">
            <v-icon color="white">{{
              this.$vuetify.breakpoint.mobile
                ? "fas fa-chevron-left"
                : "fas fa-times"
            }}</v-icon>
          </v-btn>
          <v-toolbar-title :style="'color: white;'"
            >{{ currentRecipe.name }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-container fluid class="p-1">
          <v-row class="d-flex justify-center">
            <v-col cols="12" sm="8" lg="7" md="7" class="d-flex justify-center">
              <v-img
                :src="currentRecipe.image.url"
                elevation="1"
                class="rounded thumbnail-static"
              >
              </v-img>
            </v-col>
          </v-row>
          <v-divider class="mt-2 mb-2"></v-divider>
          <v-row class="mt-0">
            <v-col cols="6" class="d-flex justify-start pt-0">
              <v-btn
                v-if="currentRecipe.isLiked"
                :loading="likeBtnLoading"
                @click="like(currentRecipe.resourceid, false)"
                elevation="0"
                :ripple="false"
                height="43"
                class="
                  font-weight-bold
                  transparent
                  text-typo
                  px-1
                  me-2
                  mt-1
                  mb-1
                "
                color="#5e72e4"
                ><v-icon
                  :elevation="'1'"
                  :style="'color: rgb(255, 63, 234); font-size: 25px !important;'"
                  >fas fa-heart mr-1</v-icon
                >
                Polubione
              </v-btn>
              <v-btn
                v-else
                :loading="likeBtnLoading"
                @click="like(currentRecipe.resourceid)"
                elevation="0"
                :ripple="false"
                height="43"
                class="
                  font-weight-bold
                  transparent
                  text-typo
                  px-1
                  me-2
                  mt-1
                  mb-1
                "
                color="#5e72e4"
                ><v-icon
                  :elevation="'1'"
                  :style="'color: rgb(255, 63, 234); font-size: 25px !important;'"
                  >far fa-heart mr-1</v-icon
                >
                Polub
              </v-btn>
            </v-col>
            <v-col
              cols="6"
              class="d-flex justify-end mt-0 pt-0"
              v-if="userHasActivePlan"
            >
              <v-btn
                v-if="
                  !diaryDay ||
                  diaryDay.meals.find(
                    (e) => e.id === currentRecipe.resourceid
                  ) === undefined
                "
                :loading="diaryLoading"
                @click="pushDiary()"
                elevation="0"
                :ripple="false"
                height="43"
                class="
                  font-weight-bold
                  transparent
                  text-typo
                  px-1
                  me-2
                  mt-1
                  mb-1
                "
                color="#5e72e4"
                ><v-icon
                  :elevation="'1'"
                  :style="'color: rgb(255, 63, 234); font-size: 25px !important;'"
                  >far fa-calendar-plus mr-1</v-icon
                >
                Wybieram
              </v-btn>
              <v-btn
                v-else-if="diaryDay"
                elevation="0"
                :ripple="false"
                height="43"
                :style="'background-color: rgb(255, 50, 198);'"
                class="
                  font-weight-bold
                  text-white
                  btn-primary
                  px-6
                  me-2
                  mt-1
                  mb-1
                "
                ><v-icon
                  :elevation="'1'"
                  :style="'color: white; font-size: 21px !important;'"
                  >fas fa-utensils mr-1</v-icon
                >
                Wybrane
              </v-btn>
            </v-col>
          </v-row>
          <v-row> </v-row>
          <v-row class="mt-0" v-if="currentRecipe.description">
            <v-col class="pt-0">
              <v-card class="p-1">
                <v-alert
                  dense
                  class="mt-2"
                  :style="'background-color: #EFDFFA;'"
                >
                  <span :style="'color: black'">
                    {{ currentRecipe.description }}
                  </span>
                </v-alert>
              </v-card>
            </v-col>
          </v-row>
          <v-divider
            class="mt-2 mb-2 ml-2 mr-2"
            v-if="currentRecipe.description"
          ></v-divider>
          <v-row class="d-flex justify-center">
            <v-col cols="6" xs="12" class="d-flex justify-center">
              <v-chip
                class="w-100 macro-chip"
                :style="'color: white; background-color: #4B4B4B;'"
              >
                {{ `kcal: ${currentRecipe.kcal}` }}
              </v-chip>
            </v-col>
            <v-col cols="6" xs="12" class="d-flex justify-center">
              <v-chip
                class="w-100 macro-chip"
                :style="'color: white; background-color: #B361FF;'"
              >
                {{ `Białko: ${currentRecipe.macro.protein}g` }}
              </v-chip>
            </v-col>
            <v-col cols="6" xs="12" class="d-flex justify-center">
              <v-chip
                class="w-100 macro-chip"
                :style="'color: white; background-color: #FF8334;'"
              >
                {{ `Tłuszcze: ${currentRecipe.macro.fat}g` }}
              </v-chip>
            </v-col>
            <v-col cols="6" xs="12" class="d-flex justify-center">
              <v-chip
                class="w-100 macro-chip"
                :style="'color: white; font-size: 11px; background-color: #FF32C6;'"
              >
                {{ `Węglowodany: ${currentRecipe.macro.carbs}g` }}
              </v-chip>
            </v-col>
          </v-row>
          <v-divider class="mt-2 mb-2 ml-2 mr-2"></v-divider>
          <v-row class="d-flex justify-center mt-0">
            <v-col>
              <template>
                <v-simple-table :style="'background-color: #EFDFFA;'">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-center">
                          <span :style="'opacity: 0 !important;'">#</span>
                        </th>
                        <th
                          class="text-center font-size-hero-medium text-black"
                        >
                          Kroki przygotowania
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(prep, index) in currentRecipe.preparation"
                        :key="index"
                      >
                        <td class="text-left pt-2 pb-2 text-black">
                          {{ `${index + 1}:` }}
                        </td>
                        <td class="text-left pt-2 pb-2 text-black">
                          {{ ` ${prep}` }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </template>
            </v-col>
          </v-row>
          <v-divider class="mt-2 mb-2 ml-2 mr-2"></v-divider>
          <v-row class="d-flex justify-center mt-0">
            <v-col cols="12" sm="12" md="6" lg="6">
              <template>
                <v-simple-table :style="'background-color: #EFDFFA;'" dense>
                  <template v-slot:default>
                    <thead>
                      <tr class="">
                        <th class="text-left">
                          <span :style="'opacity: 0 !important;'">#</span>
                        </th>
                        <th class="text-left font-size-hero-medium text-black">
                          Składniki ({{ currentRecipe.portions }} porcje)
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(ing, index) in currentRecipe.ingredients"
                        :key="index"
                      >
                        <td class="text-left pt-2 pb-2 text-black">
                          {{ `${index + 1}:` }}
                        </td>
                        <td class="text-left pt-2 pb-2 text-black">
                          {{
                            ` ${ing.name} - ${ing.unit_value}${
                              contentConfig.recipe_units[ing.unit].label
                            } ${
                              ing.alt_unit != "none"
                                ? "/ " + ing.alt_unit_value
                                : ""
                            }${
                              ing.alt_unit != "none"
                                ? contentConfig.recipe_alt_units[ing.alt_unit]
                                    .label
                                : ""
                            }`
                          }}
                          <v-spacer></v-spacer>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </template>
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="6">
              <template>
                <v-simple-table
                  class=""
                  :style="'background-color: #EFDFFA;'"
                  dense
                >
                  <template v-slot:default>
                    <thead v-if="currentRecipe.spices.length > 0">
                      <tr>
                        <th class="text-left">
                          <span :style="'opacity: 0 !important;'">#</span>
                        </th>
                        <th class="text-left font-size-hero-medium text-black">
                          Przyprawy <v-icon>fas fa-pepper-hot</v-icon>
                        </th>
                      </tr>
                    </thead>
                    <thead v-else>
                      <tr>
                        <th
                          class="text-center font-size-hero-medium text-black"
                          colspan="2"
                        >
                          Przyprawy <v-icon>fas fa-pepper-hot</v-icon>
                        </th>
                      </tr>
                    </thead>
                    <tbody v-if="currentRecipe.spices.length > 0">
                      <tr
                        v-for="(ing, index) in currentRecipe.spices"
                        :key="index"
                      >
                        <td class="text-left pt-2 pb-2 text-black">
                          {{ `${index + 1}:` }}
                        </td>
                        <td class="text-left pt-2 pb-2 text-black">
                          {{ ` ${ing.name}` }}
                          <v-spacer></v-spacer>
                        </td>
                      </tr>
                    </tbody>
                    <tbody v-else>
                      <tr>
                        <td class="text-center pt-2 pb-2" colspan="2">
                          {{ `Bez przypraw` }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </template>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
    <v-overlay :opacity="1" :style="'z-index: 999999999;'" :value="pageLoading">
      <v-progress-circular indeterminate size="120" color="#ff00d9">
      </v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
export default {
  name: "Recipes",
  data: function () {
    return {
      searchLoading: false,
      pageLoading: true,
      likeBtnLoading: false,
      diaryLoading: false,
      tab: null,
      filterDialog: false,
      recipeDialog: false,
      currentRecipe: false,
      searchValue: "",
      searchTimeout: false,
      free: [],
      categories: [],
      tags: [],
      recipes: [],
      recipes_liked: [],
      all_recipes: [],
      loading: true,
      selected: {
        free: [],
        categories: [],
        tags: [],
      },
      page:0,
      maxPage:0
    };
  },
  components: {},
  mounted() {
    this.loadFilters();
    this.loadRecipes();
    this.loadLiked();
  },
  watch: {
    selected: {
      handler: function () {
        this.loadFilters();
        this.loadRecipes();
      },
      deep: true,
    },
    recipeDialog: {
      handler: function () {
        if (!this.recipeDialog) {
          this.currentRecipe = false;
        }
      },
    },
  },
  methods: {
    pushDiary() {
      this.diaryLoading = true;

      this.$axios
        .post("/api/diary/addmeal", {
          resourceid: this.currentRecipe.resourceid,
          day:this.$getDate()
        })
        .then(async (response) => {
          if (response.data.success) {
            await this.$store.dispatch("fetchUser");
            this.$toast.open({
              message: `<span class="font-weight-bold">Dodano do dzienniczka!</span>`,
              type: "success",
              duration: 3000,
              position: "bottom",
            });
            this.diaryLoading = false;
          } else {
            this.diaryLoading = false;
            this.$toast.open({
              message: `<span class="font-weight-bold">Już dodane do dzienniczka!</span>`,
              type: "success",
              duration: 3000,
              position: "bottom",
            });
          }
        })
        .catch(function (error) {
          this.diaryLoading = false;

          console.log(error);
        });
    },
    goToTab() {
      console.log(this.tab);
    },
    openRecipe(id) {
      let chosenRecipe = this.all_recipes.find((recipe) => {
        return recipe.resourceid === id;
      });

      if (
        (chosenRecipe.is_free || this.userHasActivePlan) &&
        !this.$store.getters.contentLockedDialog
      ) {
        this.currentRecipe = chosenRecipe;
        this.recipeDialog = true;
      } else if (!this.recipeDialog) {
        this.$store.commit("setContentLockedDialog", true);
      }
    },
    closeRecipe() {
      this.currentRecipe = false;
      this.recipeDialog = false;
    },
    searchBounce() {
      try {
        clearTimeout(this.searchTimeout);
      } catch (error) {
        true;
      }
      this.searchTimeout = setTimeout(() => {
        this.loadRecipes(true);
      }, 700);
    },
    resetFilters: function () {
      this.selected = {
        free: [],
        categories: [],
        tags: [],
      };
      this.searchValue = "";
    },
    loadFilters: function () {
      this.$axios
        .post("/api/recipes/filters", {
          params: this.$_.omit(this.selected, "types"),
        })
        .then((response) => {
          this.free = response.data.free;
          this.categories = response.data.categories;
          this.tags = response.data.tags;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    loadRecipes: function (search = false) {
      if (search) {
        this.searchLoading = true;
      }
      this.$axios
        .post("/api/recipes/filtered", {
          params: { ...this.selected, search: [this.searchValue] },
        })
        .then((response) => {
          this.recipes = response.data.filtered;
          this.loadPages(response.data.filtered.length);
          this.all_recipes = response.data.all;
          this.pageLoading = false;
          this.searchLoading = false;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    loadLiked: function () {
      this.$axios
        .post("/api/recipes/liked", {
          params: this.selected,
        })
        .then((response) => {
          this.recipes_liked = response.data.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    like: function (resourceid, like = true) {
      this.likeBtnLoading = true;
      this.$axios
        .post(`/api/recipes/${like ? "like" : "unlike"}`, {
          resourceid: resourceid,
        })
        .then((response) => {
          if (response.data.success) {
            this.$set(this.currentRecipe, "isLiked", like);
            this.likeBtnLoading = false;
            this.loadRecipes();
            this.loadLiked();
          }
        })
        .catch(function (error) {
          this.likeBtnLoading = false;
          console.log(error);
        });
    },
    loadPages : function(count){
      this.page = 0;
      this.maxPage = Math.ceil(count/9);
    },
    prevPage: function(){
      if (this.page>0)
      {
        this.page-=1;
      }
    },
    nextPage: function(){
      if (this.page<(this.maxPage-1))
      {
        this.page+=1;
      }
    }
  },
  computed: {
    filtersEmpty() {
      return (
        this.selected.categories.length == 0 &&
        this.selected.tags.length == 0 &&
        this.selected.free.length == 0 &&
        (this.$vuetify.breakpoint.mdAndUp || this.searchValue == "")
      );
    },
    user() {
      return this.$store.state.user;
    },
    userHasActivePlan() {
      return this.$store.state.userHasActivePlan;
    },
    contentConfig() {
      return this.$store.state.contentConfig;
    },
    diaryDay() {
      return this.$store.state.user.diaryDay;
    },
  },
};
</script>

<style>
.free-checkbox .v-label {
  font-weight: bold;
  font-size: 15px;
  color: green !important;
}

.recommended-checkbox .v-label {
  font-weight: bold;
  font-size: 15px;
  color: rgb(229, 48, 255) !important;
}

.recipe-liked {
  position: absolute !important;
  top: 4px;
  right: 8px;
}

.recipe-diff {
  position: absolute !important;
  top: 4px;
  left: 8px;
}

/* .thumbnail-container .thumbnail-static {
  display: block;
}

.thumbnail-container .thumbnail-gif {
  display: none;
}

.thumbnail-container:hover .thumbnail-static {
  display: none;
}

.thumbnail-container:hover .thumbnail-gif {
  display: block;
} */

.recipe-search-input .v-input__prepend-outer {
  margin: 0px !important;
  padding: 10px !important;
  height: 40px !important;
}

.macro-chip {
  display: flex;
  justify-content: center;
}
</style>
